import React, { useState, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import './oneButton.css';
import { updatefollowingChat } from '../../api/service';

const OneButton = (props) => {
    const { setState, payload, actionProvider, checkedProducts } = props;
    const { url, urlAbbi, content } = payload;
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsVisible(true);
        }, 2000);

        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        const fetchData = async() => {

            try {
                let id = localStorage.getItem('followingCactusId')
                await updatefollowingChat({ selected_products: checkedProducts.join(', ') }, id);
            } catch (error) {
                console.error(error)
            }
        }
        if (checkedProducts) {
            fetchData()
        }

    }, [checkedProducts]);

    const handleClick = () => {

      if(url) {
        window.location = url;
      }

      if(urlAbbi) {
        window.open(`${urlAbbi}`, '_blank');
      }
    }

    return (
        isVisible && (
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    padding: '0 5%',
                }}
            >
                <Button onClick={handleClick}>{content}</Button>
            </Box>
        )
    );
};

export default OneButton;
