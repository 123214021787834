export const Langage = {
    "disable_dictation": {
        "french": "Désactiver la dictée",
        "english": "disable dictation",
        "chinese": "关闭听写功能"
    },
    "disable_lecture": {
        "french": "Désactiver la lecture",
        "english": "Disable Text-To-Speech",
        "chinese": "关闭文字朗读功能"
    },
    "placeholder": {
        "french": "Envoyer un message",
        "english": "Send a message",
        "chinese": "发送信息"
    },
    "routin": {
        "french": "Votre routine personalisée est en cours de traitement",
        "english": "Your personalized routine is being processed",
        "chinese": "您的个性化例程正在处理中"
    },
    "welcome": {
        "french": "Bienvenue sur GYD beauty. En utilisant cette plateforme le son doit être activé.",
        "english": "Welcome to Sam Tech. Using this platform sound must be enabled.",
        "chinese": "欢迎使用Sam Tech,在使用此平台时，必须开启声音功能。"
    },
    "start_diag": {
        "french": "Commencons",
        "english": "Let's start",
        "chinese": "开始吧"
    },
    "start": {
        "french": "Bonjour! Je suis ton assistant personnel, une IA conçue pour déterminer tes besoins et les produits adaptés. Mais avant, faisons connaissance! Quel est ton prénom?",
        "english": "Hello! I am your personal assistant, an AI designed to determine your needs and the right products. But first, let's get to know each other! What's your firstname?",
        "chinese": "你好！我是您的个人助手，一个旨在满足您的需求并找到合适产品的人工智能。但首先，让我们互相了解一下吧！您叫什么名字？"
    },
    "hello": {
        "french": "Bonjour",
        "english": "Hello",
        "chinese": "您好"
    },
    "hello_again": {
        "french": "heureux de vous revoir",
        "english": "welcome back",
        "chinese": "欢迎回来"
    },
    "age": {
        "french": "Quel âge as-tu?",
        "english": "How old are you?",
        "chinese": "您的年龄是？"
    },
    "gender": {
        "french": "Es-tu une femme, un homme ou ne souhaites-tu pas te définir?",
        "english": "Are you a woman, a man, or do you not want to define yourself?",
        "chinese": "您是性别是？男性，女性，或者您可以忽略跳过性别选择。"
    },
    "woman": {
        "french": "Femme",
        "english": "Woman",
        "chinese": "女性"
    },
    "man": {
        "french": "Homme",
        "english": "Man",
        "chinese": "男性"
    },
    "not_define": {
        "french": "Non-binaire",
        "english": "None",
        "chinese": "跳过回答"
    },
    "start_experience": {
        "french": "Démarrons l’expérience! Nous allons analyser tes problématiques de peau grâce à une analyse par IA, un diagnostic validé par des dermatologues.",
        "english": "Let's start the experience! We will analyze your skin problems through an AI analysis, a diagnosis validated by dermatologists.",
        "chinese": "让我们开始体验吧！我们将通过人工智能分析来分析您的皮肤问题，这是经皮肤科医生验证的诊断。"
    },
    "right_profil": {
        "french": "Veuillez prendre une photo de votre profil droit",
        "english": "Please take a photo of your right profile",
        "chinese": "请拍摄一张您右侧轮廓的照片"
    },
    "left_profil": {
        "french": "Veuillez prendre une photo de votre profil gauche",
        "english": "Please take a photo of your left profile",
        "chinese": "请拍摄一张您左侧轮廓的照片"
    },
    "start_experience_hair": {
        "french": "Démarrons l’expérience!",
        "english": "Let's start the experience!",
        "chinese": "让我们开始体验吧！"
    },
    "determine": {
        "french": "Nous avons déterminé que votre peau est à",
        "english": "We have determined that your skin is",
        "chinese": "我们已经确定您的皮肤是："
    },
    "today_analysis": {
        "french": "D'après le selfie, voici votre peau aujourd'hui :",
        "english": "Based on the selfie, this is your skin today:",
        "chinese": "根据自拍照片，这是您今天的皮肤状况："
    },
    "i_will_propose": {
        "french": "Je vais te proposer des produits qui répondent aux besoins de ta peau. Clique sur les besoins que tu souhaites prioriser ci-dessous",
        "english": "I will suggest products that meet the needs of your skin. Click on the needs you want to prioritize below",
        "chinese": "我将建议符合您皮肤需求的产品。请点击下面您想优先考虑的需求："
    },
    "here_your_analysis": {
        "french": "Voici les résultats de votre analyse",
        "english": "Here are the results of your analysis",
        "chinese": "以下是您分析的结果："
    },
    "here_your_presc": {
        "french": "Voici votre prescription",
        "english": "Here is your prescription",
        "chinese": "以下是您的处方："
    },
    "continue": {
        "french": "continue 2ème partie",
        "english": "continue part 2",
        "chinese": "继续完成第二部分"
    },
    "i_choose_type": {
        "french": "Je choisis mon type de peau",
        "english": "I choose my skin type",
        "chinese": "选择我的皮肤类型"
    },
    "you_can_specify": {
        "french": "Vous pouvez préciser vos besoins en cliquant sur les curseurs.",
        "english": "You can specify your needs by clicking on the sliders.",
        "chinese": "选择我的皮肤类型"
    },
    "have_alergy": {
        "french": "As-tu des allergies?",
        "english": "Do you have allergies?",
        "chinese": "您有过敏反应吗？"
    },
    "with_one": {
        "french": "Si oui lesquelles?",
        "english": "If yes, which ones?",
        "chinese": "如果有，请描述何种过敏？"
    },
    "yes": {
        "french": "Oui",
        "english": "Yes",
        "chinese": "有"
    },
    "nope": {
        "french": "Non",
        "english": "No",
        "chinese": "没有"
    },
    "rides_indique": {
        "french": "Peux-tu m'indiquer où sont situées tes rides?",
        "english": "Can you tell me where your wrinkles are located?",
        "chinese": "您能告诉我您皱纹出现在哪些部位？"
    },
    "eye_circle": {
        "french": "Autour de l'oeil",
        "english": "around the eye",
        "chinese": "眼部周围"
    },
    "in_front": {
        "french": "Sur le front",
        "english": "On the forehead",
        "chinese": "额头"
    },
    "bouche_circle": {
        "french": "Autour de la bouche",
        "english": "around the mouth",
        "chinese": "嘴唇周围"
    },
    "rides_sourc": {
        "french": "Entre les sourcils (ride du lion)",
        "english": "Between the eyebrows (frown lines)",
        "chinese": "在两眉之间（皱眉纹）"
    },
    "partout": {
        "french": "Partout",
        "english": "Everywhere",
        "chinese": "整个面部"
    },
    "want_relachement": {
        "french": "Voudrais-tu travailler sur le relâchement cutané?",
        "english": "Would you like to work on sagging skin?",
        "chinese": "您是否希望改善皮肤的松弛状况？"
    },
    "i_want_relachement": {
        "french": "Je souhaites travailler sur le relâchement cutané",
        "english": "I want to work on sagging skin",
        "chinese": "我希望改善皮肤的松弛状况"
    },
    "eczema_concern": {
        "french": "Es-tu concerné·e par de l'eczéma ou de la rosacée?",
        "english": "Do you have eczema or rosacea?",
        "chinese": "您是否患有湿疹或红斑狼疮？"
    },
    "i_m_eczema_concern": {
        "french": "Je suis concerné par de l'eczéma ou de la rosacée",
        "english": "I have eczema or rosacea",
        "chinese": "我患有湿疹或红斑狼疮"
    },
    "product_correspondent": {
        "french": "Parmi ces produits, lesquels te correspondent?",
        "english": "Which of these products are right for you?",
        "chinese": "以下哪些产品适合您？"
    },
    "requilibrant": {
        "french": "Rééquilibrant",
        "english": "Rebalancing",
        "chinese": "重新平衡"
    },
    "none_sulfate": {
        "french": "Sans sulfate",
        "english": "Sulfate free",
        "chinese": "不含硫酸盐"
    },
    "purifiant": {
        "french": "Purifiant",
        "english": "Purifying",
        "chinese": "洁净"
    },
    "matifiant": {
        "french": "Matifiant",
        "english": "Mattifying",
        "chinese": "控油"
    },
    "boutons_indique": {
        "french": "Peux-tu m'indiquer où sont situés tes boutons?",
        "english": "Can you tell me where your pimples are located?",
        "chinese": "您能告诉我您的痘痘分布在哪里吗？"
    },
    "zone_t": {
        "french": "Zone T",
        "english": "T Zone",
        "chinese": "面部T型区"
    },
    "in_joues": {
        "french": "Sur les joues",
        "english": "On the cheeks",
        "chinese": "两颊"
    },
    "locale": {
        "french": "Localisés",
        "english": "Localized",
        "chinese": "局部"
    },
    "see_boutons": {
        "french": "Ces boutons sont-ils présents?",
        "english": "Are these pimples present?",
        "chinese": "这些痘痘现在是否存在？"
    },
    "permanence": {
        "french": "En permanence",
        "english": "Always",
        "chinese": "一直有"
    },
    "episod": {
        "french": "De manière épisodique",
        "english": "Occasionally",
        "chinese": "偶尔有"
    },
    "remove_taches": {
        "french": "Quel type de tâches souhaites-tu éliminer / réduire?",
        "english": "What kind of blemishes do you want to eliminate/reduce?",
        "chinese": "您想消除/减少哪种瑕疵？"
    },
    "rouss_taches": {
        "french": "Tâches de rousseur",
        "english": "Freckles",
        "chinese": "雀斑"
    },
    "old_taches": {
        "french": "Tâches de vieillesse",
        "english": "Age spots",
        "chinese": "老化区域"
    },
    "vin_taches": {
        "french": "Tâches de vin",
        "english": "Wine stains",
        "chinese": "鲜红斑痣"
    },
    "birth_taches": {
        "french": "Tâches de naissance",
        "english": "Birthmarks",
        "chinese": "妊娠纹"
    },
    "pregnant_taches": {
        "french": "Tâches de grossesse",
        "english": "Pregnancy stains",
        "chinese": "孕妇斑痕"
    },
    "hydrate": {
        "french": "Quelles sont les manifestations de la déshydratation cutanée?",
        "english": "What are the signs of skin dehydration?",
        "chinese": "皮肤缺水的迹象有哪些？"
    },
    "teint_terne": {
        "french": "Teint terne",
        "english": "Dull complexion",
        "chinese": "肤色暗沉"
    },
    "stries": {
        "french": "Stries",
        "english": "Stretch marks",
        "chinese": "妊娠纹"
    },
    "souplesse": {
        "french": "Manque de souplesse",
        "english": "Lack of skin suppleness",
        "chinese": "皮肤缺乏柔软性"
    },
    "traillements": {
        "french": "Tiraillements",
        "english": "Skin tightness",
        "chinese": "皮肤紧绷"
    },
    "demangeaisons": {
        "french": "Démangeaisons",
        "english": "Itching",
        "chinese": "瘙痒"
    },
    "visible_pores": {
        "french": "Tes pores sont-ils toujours visibles ou seulement à certains moments de la journée?",
        "english": "Are your pores always visible or only at certain times of the day?",
        "chinese": "您的毛孔是否总是可见，还是只在一天的某些时候可见？"
    },
    "never": {
        "french": "Jamais",
        "english": "Never",
        "chinese": "不可见"
    },
    "some_time": {
        "french": "Parfois",
        "english": "Sometimes",
        "chinese": "有时可见"
    },
    "always": {
        "french": "Toujours",
        "english": "Always",
        "chinese": "总是可见"
    },
    "day_period": {
        "french": "Dépendant du moment de la journée",
        "english": "Depending on the time of day",
        "chinese": "一天中某些时间可见"
    },
    "pores_light": {
        "french": "Pores légèrement apparents",
        "english": "Slightly visible pores",
        "chinese": "基本不可见"
    },
    "pores_exposed": {
        "french": "Pores apparents",
        "english": "Visible pores",
        "chinese": "可见毛孔"
    },
    "pores_sometimes": {
        "french": "Pores parfois apparents",
        "english": "Sometimes visible pores",
        "chinese": "有时可见毛孔"
    },
    "cicatrice_on_face": {
        "french": "As-tu des cicatrices sur le visage?",
        "english": "Do you have scars on your face?",
        "chinese": "您面部是否有疤痕？"
    },
    "none": {
        "french": "Aucune",
        "english": "None",
        "chinese": "没有"
    },
    "some": {
        "french": "Quelques-unes",
        "english": "Some",
        "chinese": "一些"
    },
    "a_lot": {
        "french": "Beaucoup",
        "english": "A lot",
        "chinese": "很多"
    },
    "pores_scars": {
        "french": "J'ai des cicatrices sur le visage",
        "english": "I have scars on my face",
        "chinese": "我面部有疤痕"
    },
    "pores_scars_alot": {
        "french": "J'ai beaucoup de cicatrices sur le visage",
        "english": "I have a lot of scars on my face",
        "chinese": "我面部有很多疤痕"
    },
    "pollue_city": {
        "french": "Vis-tu dans un environnement pollué?",
        "english": "Do you live in a polluted environment?",
        "chinese": "您是否生活在一个受污染的环境？"
    },
    "pollue_a_lot": {
        "french": "Très pollué",
        "english": "Very polluted",
        "chinese": "严重污染"
    },
    "pollue_average": {
        "french": "Moyennement pollué",
        "english": "Moderately polluted",
        "chinese": "中度污染"
    },
    "pollue_little": {
        "french": "Peu pollué",
        "english": "Little polluted",
        "chinese": "轻微污染"
    },
    "none_pollue": {
        "french": "Pas du tout pollué",
        "english": "Not polluted at all",
        "chinese": "完全没有受到污染"
    },
    "product_interess": {
        "french": "Es-tu intéressé·e par des produits autobronzants ou plutôt par des produits \"coup d'éclat\"",
        "english": "Are you interested in self-tanning products or rather in \"radiance boost\" products?",
        "chinese": "您是对自主美黑产品感兴趣，还是更倾向于“提升肌肤光泽”的产品？"
    },
    "eclat_coup": {
        "french": "Coup d'éclat",
        "english": "Radiance boost",
        "chinese": "提升肌肤光泽"
    },
    "autbronzant": {
        "french": "Auto-bronzant",
        "english": "Self-tanning",
        "chinese": "自主美黑"
    },
    "desire_product": {
        "french": "Comment désires-tu ton produit?",
        "english": "How do you want your product?",
        "chinese": "您希望如何定制您的产品？"
    },
    "vegan": {
        "french": "Vegan",
        "english": "Vegan",
        "chinese": "纯素主义"
    },
    "bio": {
        "french": "Bio",
        "english": "Organic",
        "chinese": "有机的"
    },
    "natural": {
        "french": "D'origine naturelle",
        "english": "Natural origin",
        "chinese": "天然来源"
    },
    "made_in_france": {
        "french": "Fabriqué en France",
        "english": "Made in France",
        "chinese": "法国制造"
    },
    "renseigne_email": {
        "french": "Ta prescription de produits personnalisés va être élaborée, renseigne ton email pour la reçevoir.",
        "english": "Your personalized product prescription will be elaborated, fill in your email to receive it.",
        "chinese": "您的个性化产品处方正在生成中，请填写您的电子邮件地址以接收它。"
    },
    "your_products": {
        "french": "Tu ne trouveras dans cette page que les produits qui te correspondent.\nLa peau évolue tous les jours, c’est pour ça que c’est important de lui apporter les actifs essentiels dont elle a besoin à chaque moment.",
        "english": "You will only find on this page the products that suit you. The skin evolves every day, that's why it's important to provide it with the essential active ingredients it needs at all times.",
        "chinese": "您将会在这个页面上找到适合您的产品。皮肤每天都在变化，这就是为什么随时为它提供必要的活性成分非常重要。"
    },
    "take_selfie": {
        "french": "Prendre mon selfie",
        "english": "Take my selfie",
        "chinese": "请拍摄一张面部自拍照片"
    },
    "take_selfie_hair": {
        "french": "Prendre la photo globale de ma chevelure",
        "english": "Please take a selfie of your hair",
        "chinese": "请拍摄一张您的头发的照片"
    },
    "take_selfie_scalp": {
        "french": "Prendre une photo de mon cuir chevelu",
        "english": "Please take a selfie of your scalp",
        "chinese": "请拍摄一张您的头皮的照片"
    },
    "skip": {
        "french": "Passer",
        "english": "Skip",
        "chinese": "跳過"
    },
    "my_analysis": {
        "french": "Analyse de la peau en cours",
        "english": "Skin analysis in progress",
        "chinese": "正在分析您的皮肤，请稍后"
    },
    "process_analysis": {
        "french": "Analyse en cours :",
        "english": "Analysis in progress",
        "chinese": "正在分析中"
    },
    "rides": {
        "french": "Rides",
        "english": "Wrinkles",
        "chinese": "皱纹"
    },
    "taches": {
        "french": "Taches",
        "english": "Stains",
        "chinese": "斑点"
    },
    "imperfections": {
        "french": "Imperfections",
        "english": "Imperfections",
        "chinese": "瑕疵"
    },
    "sensibilite": {
        "french": "Sensibilité",
        "english": "Sensitivity",
        "chinese": "敏感性"
    },
    "hydratation": {
        "french": "Hydratation",
        "english": "Hydration",
        "chinese": "保湿"
    },
    "brillance": {
        "french": "Brillance",
        "english": "Shine",
        "chinese": "光泽"
    },
    "eclat": {
        "french": "Éclat",
        "english": "Glow",
        "chinese": "肌肤光彩"
    },
    "pores": {
        "french": "Grain de peau",
        "english": "Skin texture",
        "chinese": "皮肤质地"
    },
    "see_result": {
        "french": "Voir les résultats détaillés",
        "english": "See detailed results",
        "chinese": "查看详细结果"
    },
    "ok": {
        "french": "OK",
        "english": "OK",
        "chinese": "好"
    },
    "i_accept": {
        "french": "J'accepte",
        "english": "I Accept",
        "chinese": "同意"
    },
    "my_result": {
        "french": "Mes résultats",
        "english": "My results",
        "chinese": "我的结果"
    },
    "confirm": {
        "french": "Confirmer",
        "english": "Confirm",
        "chinese": "确认"
    },
    "presc_on_way": {
        "french": "Élaboration de la \n prescription en cours",
        "english": "Prescription in progress",
        "chinese": "处方正在制定中。请稍等片刻，我会尽快为您提供。"
    },
    "see_our_offer": {
        "french": "En attendant ta \n prescription, découvre \n notre offre exclusive.",
        "english": "While waiting for your \n prescription, discover our exclusive offer.",
        "chinese": "在等待您的处方时，可以看看我们的独家优惠。"
    },
    "i_have_products": {
        "french": "Super, j’ai la sélection de \n  produits qu’il te faut!",
        "english": "Great, I've got the selection of products you need!",
        "chinese": "太好了，我已经为您选择了所需的产品！如果您需要进一步的建议或帮助，请随时告诉我。"
    },
    "see_ma_selection": {
        "french": "Voir ma sélection",
        "english": "See my selection",
        "chinese": "查看我的选择"
    },
    "discover_tm": {
        "french": "Découvrez les soins de la peau sur mesure",
        "english": "Discover Tailor maid skin care",
        "chinese": "探索裁縫女僕皮膚護理"
    },
    "here_your_result": {
        "french": "Voici les résultats de l'analyse de peau",
        "english": "Here are the results of the skin analysis",
        "chinese": "这是皮肤分析的结果"
    },
    "close": {
        "french": "Fermer",
        "english": "Close",
        "chinese": "关闭"
    },
    "age_q": {
        "french": "Âge :",
        "english": "Age:",
        "chinese": "年龄："
    },
    "gender_q": {
        "french": "Genre :",
        "english": "Gender:",
        "chinese": "性别："
    },
    "today_type": {
        "french": "Voici ton type de peau aujourd'hui :",
        "english": "Here's your skin type today:",
        "chinese": "这是您今天的肤质状况："
    },
    "brief_allergie": {
        "french": "Allergies :",
        "english": "Allergies:",
        "chinese": "过敏："
    },
    "brief_rides": {
        "french": "Mes rides se situent ",
        "english": "My wrinkles are located ",
        "chinese": "我的皱纹长在"
    },
    "brief_product_correspondent": {
        "french": "Pour éviter la brillance, je recherche un produit ",
        "english": "To avoid shine, I am looking for a product ",
        "chinese": "为了避免皮肤出现油光，我正在寻找一种产品"
    },
    "ask_question": {
        "french": "Nous sommes en train d’analyser tes cheveux, nous allons compléter l’analyse avec quelques questions.",
        "english": "We are currently analyzing your hair, and will complete the analysis with a few questions.",
        "chinese": "我们目前正在分析您的头发，并将通过一些问题完成分析。"
    },
    "brief_boutons_found": {
        "french": "Mes boutons se trouvent ",
        "english": "My pimples are located ",
        "chinese": "我的痘痘长在"
    },
    "brief_boutons_present": {
        "french": "Mes boutons sont présents ",
        "english": "My pimples are present ",
        "chinese": "我的痘痘依然存在"
    },
    "brief_taches": {
        "french": "Je souhaite réduire les ",
        "english": "I want to reduce the ",
        "chinese": "我想要减少"
    },
    "brief_hydrate": {
        "french": "Déshydratation : ",
        "english": "Dehydration: ",
        "chinese": "缺水“"
    },
    "brief_eclat_env": {
        "french": "Environnement : ",
        "english": "Environment: ",
        "chinese": "环境："
    },
    "brief_eclat": {
        "french": "Pour l'éclat de mon visage je recherche un produit ",
        "english": "For the radiance of my face I am looking for a product ",
        "chinese": "为了增强我的面部光泽，我正在寻找一种产品"
    },
    "brief_desire_product": {
        "french": "Je souhaite que mon produit soit : ",
        "english": "I want my product to be: ",
        "chinese": "我希望我的产品是："
    },
    "SECHE": {
        "french": "<b>L'analyse est terminée, j'ai fait une étude complète de ton profil :</b>\n    <br> Ta peau\n    présente des stries, un grain de peau serré et un teint terne, qui sont les signes d'une\n    peau à tendance sèche.<br><br>\n    <b>Mon conseil :</b><br> élimine régulièrement les cellules mortes par un nettoyant non\n    agressif, préfère une huile démaquillante à une eau micellaire, nourris\n    régulièrement ta peau avec des masques adaptés",
        "english": "<b>The analysis is complete, I have made a complete study of your profile:</b>\n     <br> Your skin\n     has streaks, tight skin texture and a dull complexion, which are signs of a\n     dry skin.<br><br>\n     <b>My advice:</b><br> Regularly remove dead cells with a cleanser not\n     aggressive, prefers a cleansing oil to a micellar water, nourishes\n     your skin regularly with suitable masks",
        "chinese": "<b>分析已完成，我已经对您的个人资料进行了全面研究：</b><br> 您的皮肤出现了脱皮、皮肤质地紧绷和肤色晦暗，这些是干性皮肤的迹象。<br><br> <b>我的建议：</b><br>使用不刺激的洁面剂定期去除死皮细胞，更喜欢洁面油而不是生活水，定期使用合适的面膜滋养您的皮肤。"
    },
    "GRASSE": {
        "french": "<b>L'analyse est terminée, j'ai fait une étude complète de ton profil :</b>\n    <br>Ta peau présente des\n    pores irréguliers, dilatés avec la présence de points noirs, de boutons, un excès de\n    brillance, ce sont les signes d'une peau grasse<br><br>\n    <b>Mon conseil :</b> <br>nettoie ta peau régulièrement avec des produits doux, sans alcool et\n    sans savon, hydrate la peau, utilise des masques purifiants, évite les produits à base\n    d'huile",
        "english": "<b>The analysis is complete, I have made a complete study of your profile:</b>\n     <br>Your skin has\n     irregular, dilated pores with the presence of blackheads, pimples, excess\n     shine, these are the signs of oily skin<br><br>\n     <b>My advice:</b> <br>cleanse your skin regularly with gentle, alcohol-free products\n     soap-free, hydrates the skin, uses purifying masks, avoids products containing\n     of oil",
        "chinese": "<b>分析已完成，我已经对您的个人资料进行了全面研究：</b><br> 您的皮肤毛孔不规则，扩张，并有黑头、痘痘和过度油光，这些是油性皮肤的迹象。<br><br> <b>我的建议：</b><br>使用温和、无酒精、无皂的产品定期清洁您的皮肤，保湿皮肤，使用净化面膜，避免使用含油产品。"
    },
    "DESHYDRATEE": {
        "french": "<b>L'analyse est terminée, j'ai fait une étude complète de ton profil : </b>\n    <br>Ta peau présente\n    de fines ridules, l'éclat dû teint est légèrement terne, le grain de peau est serré, la\n    sensibilité est présente et la peau nécessite un fort besoin d'hydratation, ce sont les\n    signes d'une peau déshydratée<br><br>\n    <b>Mon conseil :</b> protége ta peau du froid, du soleil et du vent, évite les produits\n    détergents, évite la présence de climatisation, privilégie des produits riches en\n    lipides",
        "english": "<b>The analysis is complete, I have made a complete study of your profile: </b>\n     <br>Your skin presents\n     fine lines, the radiance of the complexion is slightly dull, the skin texture is tight, the\n     sensitivity is present and the skin requires a strong need for hydration, these are the\n     signs of dehydrated skin<br><br>\n     <b>My advice:</b> protect your skin from the cold, the sun and the wind, avoid products\n     detergents, avoid the presence of air conditioning, favor products rich in\n     lipids",
        "chinese": "<b>分析已完成，我已经对您的个人资料进行了全面研究：</b><br> 您的皮肤出现了细纹，肤色略显晦暗，皮肤质地紧绷，有敏感性，并且皮肤急需强力保湿，这些是脱水皮肤的迹象。<br><br> <b>我的建议：</b>保护您的皮肤免受寒冷、阳光和风的影响，避免使用去污剂性的产品，避免长时间处于空调环境中，选择富含脂质的护肤产品。"
    },
    "MATURE": {
        "french": "<b>L'analyse est terminée, j'ai fait une étude complète de ton profil :</b>\n    <br>Ta peau révèle\n    des rides sur les zones d'expression, le teint est terne, on note des stries de\n    déshydratation, ce sont les signes d'une peau Mature\n    <b>Mon conseil :</b><br><br>utilise des gommages doux 2 fois par semaine, hydrate ta peau\n    chaque jour, utilise un soin anti-rides le jour et utilise un sérum anti-âge et une\n    crème repulpante le soir, protège ta peau du soleil et de la pollution",
        "english": "<b>The analysis is complete, I have made a complete study of your profile:</b>\n     <br>Your skin reveals\n     wrinkles on the expression areas, the complexion is dull, there are streaks of\n     dehydration, these are the signs of mature skin\n     <b>My advice:</b><br><br>use gentle scrubs twice a week, moisturize your skin\n     every day, use an anti-wrinkle day care and use an anti-aging serum and a\n     plumping cream in the evening, protect your skin from the sun and pollution",
        "chinese": "<b>分析已完成，我已经对您的个人资料进行了全面研究：</b><br> 您的皮肤在表情区域出现皱纹，肤色晦暗，有脱水的迹象，这些是成熟皮肤的迹象。 <b>我的建议：</b><br><br> 每周使用温和的磨砂产品两次，每天保湿您的皮肤，使用抗皱日间护理产品，并在晚间使用抗衰老精华和滋润霜，保护您的皮肤免受太阳和污染的伤害。"
    },
    "SENSIBLE": {
        "french": "<b>L'analyse est terminée, j'ai fait une étude complète de ton profil :</b> \n    <br>le type de peau qui\n    te correspond est une peau sensible . une peau sensible a une tendance à la\n    réactivité aux produits cosmétiques avec du parfum, des conservateurs, des tensio-\n    actifs\n    <b>Mon conseil :</b><br><br>Évite les gommages agressifs, utilise un écran total, évite le tabac,\n    l'alcool, protège-toi des pollutions, de la climatisation,, sèche ton visage sans\n    frotter mais en tamponnant",
        "english": "<b>The analysis is complete, I have made a complete study of your profile:</b>\n     <br>the type of skin that\n     corresponds to you is a sensitive skin. sensitive skin has a tendency to\n     reactivity to cosmetic products with perfume, preservatives, surfactants\n     <b>My advice:</b><br><br>Avoid harsh scrubs, use sunblock, avoid tobacco,\n     alcohol, protect yourself from pollution, air conditioning, dry your face without\n     rubbing but dabbing",
        "chinese": "<b>分析已完成，我已经对您的个人资料进行了全面研究：</b><br> 您的皮肤类型是敏感皮肤。敏感皮肤容易对带有香水、防腐剂、表面活性剂的化妆品产生过敏反应。 <b>我的建议：</b><br><br> 避免使用刺激性的磨砂产品，使用防晒霜，避免烟草和酒精，保护自己免受污染和空调的影响，脸部擦干时避免摩擦而是轻轻拍干。"
    },
    "ACNEIQUE": {
        "french": "<b>L'analyse est terminée, j'ai fait une étude complète de ton profil :</b>\n    <br>Ta peau présente\n    de nombreux boutons, des points noirs, les pores sont dilatés, la brillance est\n    excessive, ce sont les signes d'une peau Acnéique\n    Mon conseil :<br><br> Hydrate régulièrement ta peau, nettoie ta peau matin et soir avec des\n    produits doux, sans savon, utilise des crèmes matifiantes pour masquer les défauts",
        "english": "<b>The analysis is complete, I have made a complete study of your profile:</b>\n     <br>Your skin presents\n     many pimples, blackheads, pores are enlarged, shine is\n     excessive, these are the signs of acne-prone skin\n     My advice: <br><br> Moisturize your skin regularly, cleanse your skin morning and evening with\n     gentle products, without soap, use mattifying creams to conceal blemishes",
        "chinese": "<b>分析已完成，我已经对您的个人资料进行了全面研究：</b><br> 您的皮肤出现了很多痘痘、黑头，毛孔扩大，过度油光，这些是痘痘皮肤的迹象。 <b>我的建议：</b><br><br> 定期保湿您的皮肤，早晚使用温和的无皂产品清洁您的皮肤，使用控油面霜来遮盖瑕疵。"
    },
    "EQUILIBREE": {
        "french": "<b>L'analyse est terminée, j'ai fait une étude complète de ton profil :</b> \n    <br>Ta peau présente\n    de bons scores sur tous les critères étudiés : l'éclat du teint est bon, le grain de peau\n    lisse, l'hydratation parfaite, très peu de sensibilité et de brillance, ce sont les signes\n    d'une peau équilibrée\n    Mon conseil :<br><br> nettoie régulièrement ta peau, hydrate-la tous les jours, exfolie les\n    peaux mortes 1 à 2 fois par semaine, protège ta peau du soleil",
        "english": "<b>The analysis is complete, I have made a complete study of your profile:</b>\n     <br>Your skin presents\n     good scores on all the criteria studied: the radiance of the complexion is good, the skin texture\n     smooth, perfect hydration, very little sensitivity and shine, these are the signs\n     of balanced skin\n     My advice: <br><br> regularly cleanse your skin, moisturize it daily, exfoliate the\n     dead skin 1 to 2 times a week, protect your skin from the sun",
        "chinese": "<b>分析已完成，我已经对您的个人资料进行了全面研究：</b><br> 您的皮肤在所有研究的标准上得分良好：肤色光泽良好，皮肤质地光滑，保湿完美，敏感度和油光很少，这些是平衡性皮肤的迹象。 <b>我的建议：</b><br><br> 定期清洁皮肤，每天保湿，每周进行1到2次的死皮去除，保护皮肤免受阳光的伤害。"
    },
    "MIXTE": {
        "french": "<b>L'analyse est terminée, j'ai fait une étude complète de ton profil :</b> \n    <br>Ta peau présente\n    des points noirs sur la zone T, les pores sont dilatés sur la zone T et sont serrés sur le\n    reste du visage, tu ressens des tiraillements localisés, le teint est terne sur les joues\n    et brillant sur la zone T, ce sont les signes d'une peau mixte\n    <b>Mon conseil :</b><br><br>il est important de contrôler l'excès de sébum des zones grasses sans\n    dessécher davantage les zones plus sèches. Il est donc conseillé de privilégier des\n    produits non gras et d'éviter tous les produits pouvant contenir de l'alcool",
        "english": "<b>The analysis is complete, I have made a complete study of your profile:</b>\n     <br>Your skin presents\n     blackheads on the T-zone, the pores are dilated on the T-zone and are tight on the\n     rest of the face, you feel localized tightness, the complexion is dull on the cheeks\n     and shiny on the T-zone, these are the signs of combination skin\n     <b>My advice:</b><br><br>it is important to control excess sebum in oily areas without\n     further desiccating drier areas. It is therefore advisable to favor\n     non-greasy products and avoiding all products that may contain alcohol",
        "chinese": "<b>分析已完成，我已经对您的个人资料进行了全面研究：</b><br> 您的皮肤在T区出现黑头，T区的毛孔扩张，而在面部的其他部分则较为紧致。您感到局部的紧绷感，脸颊皮肤晦暗而T区较为光亮，这些是混合性皮肤的迹象。 <b>我的建议：</b><br><br> 重要的是要控制油脂过多的部位，而不要进一步干燥较干燥的区域。因此，建议选择不含油脂的产品，并避免使用可能含有酒精的所有产品。"
    },
    "start_diag2": {
        "french": 'Commencons le diagnostique',
        "english": 'Let\'s start the diagnosis',
        "chinese": '让我们开始诊断'
    },
    wrinklesRight: {
        english: 'Mature skin',
        french: 'Peau mature',
        chinese: ''
    },
    wrinklesLeft: {
        english: 'Smooth skin',
        french: 'Visage Lisse',
        chinese: ''
    },
    hydrationRight: {
        english: 'Dehydrated skin',
        french: 'Peau déshydratée',
        chinese: ''
    },
    hydrationLeft: {
        english: 'Tonic skin',
        french: 'Peau tonique',
        chinese: ''
    },
    regulationRight: {
        english: 'Excess sebum',
        french: 'Excès de sébum',
        chinese: ''
    },
    regulationLeft: {
        english: 'Balanced Microbiome',
        french: 'Microbiote Equilibré',
        chinese: ''
    },
    sensitivityRight: {
        english: 'Hyper sensitive skin',
        french: 'Peau hyper réactive',
        chinese: ''
    },
    sensitivityLeft: {
        english: 'Well balanced',
        french: 'Bon équilibre',
        chinese: ''
    },
    poresRight: {
        english: 'Skin texture',
        french: 'Grain de peau dilatés',
        chinese: ''
    },
    poresLeft: {
        english: 'Uniform skin texture',
        french: 'Grain de peau uniforme',
        chinese: ''
    },
    pimplesRight: {
        english: 'Imperfections present',
        french: 'Imperfections présentes',
        chinese: ''
    },
    pimplesLeft: {
        english: 'Clear skin',
        french: 'Peau nette',
        chinese: ''
    },
    radianceRight: {
        english: 'Radiant complexion',
        french: 'Teint éclatant',
        chinese: ''
    },
    radianceLeft: {
        english: 'Dull complexion',
        french: 'Teint terne',
        chinese: ''
    },
    listening: {
        english: 'Listening...',
        french: 'En écoute...',
        chinese: 'Listening...'
    }
}

export const LangageHair = {
    "hair_type": {
        "french": "Tes cheveux sont :",
        "english": "Your hair type:",
        "chinese": "发质"
    },
    "other": {
        "french": "Autre",
        "english": "Other",
        "chinese": "其他"
    },
    "damaged": {
        "french": "abimés",
        "english": "damaged",
        "chinese": "受损"
    },
    "weak": {
        "french": "fragiles",
        "english": "weak",
        "chinese": "受损脆弱的头发"
    },
    "breakage": {
        "french": "cassants",
        "english": "breakage ",
        "chinese": "脱发"
    },
    "forked": {
        "french": "fourchus",
        "english": "forked ",
        "chinese": "分叉"
    },
    "dehydrate": {
        "french": "déshydratés",
        "english": "dehydrate ",
        "chinese": "脱水"
    },
    "qualified_hairdresser":{
        "french": "Ton coiffeur qualifie tes cheveux de :",
        "english": "Your hairdresser describes your hair as : ",
        "chinese": "您的理发师将您的头发描述为 ："
    },
    "steep": {
        "french": "Raides",
        "english": "Straight",
        "chinese": "直发"
    },
    "corrugated": {
        "french": "Ondulés",
        "english": "Wavy",
        "chinese": "波浪"
    },
    "looped": {
        "french": "Bouclés",
        "english": "Curly",
        "chinese": "自然卷曲"
    },
    "curly": {
        "french": "Frisés",
        "english": "Curled",
        "chinese": "烫卷"
    },
    "frizzy": {
        "french": "Crépus",
        "english": "Frizzy",
        "chinese": "小卷"
    },
    "perfect":{
        "french": "parfaits",
        "english": "perfect",
        "chinese": "完美"
    },
    "hair_thickness": {
        "french": "Tes cheveux sont :",
        "english": "Your hair thickness:",
        "chinese": "您的发量"
    },
    "fins": {
        "french": "Fins",
        "english": "Thin",
        "chinese": "稀疏"
    },
    "normals": {
        "french": "Normaux",
        "english": "Normals",
        "chinese": "正常"
    },
    "thick": {
        "french": "Épais",
        "english": "Thick",
        "chinese": "浓厚"
    },
    "scalp": {
        "french": "Ton cuir chevelu semble",
        "english": "Your scalp looks:",
        "chinese": "您的头皮状况："
    },
    "sec": {
        "french": "Sec",
        "english": "Dry",
        "chinese": "干燥"
    },
    "gras": {
        "french": "Gras",
        "english": "Greasy",
        "chinese": "多油"
    },
    "short": {
        "french": "Courts",
        "english": "Short",
        "chinese": "短"
    },
    "normal": {
        "french": "Normal",
        "english": "Normal",
        "chinese": "正常"
    },
    "long": {
        "french": "Longs",
        "english": "Long",
        "chinese": "长"
    },
    "bald": {
        "french": "Chauve",
        "english": "Bald",
        "chinese": "秃"
    },
    "kinky": {
        "french": "Crépus",
        "english": "Kinky",
        "chinese": "淫"
    },
    "mi_long": {
        "french": "Mi-longs",
        "english": "Medium-long",
        "chinese": "中等长度"
    },
    "goals": {
        "french": "Quelle est la couleur de tes cheveux?",
        "english": "What is your hair color?",
        "chinese": "您的发色是？"
    },
    "goals_beauty": {
        "french": "Quelle est ton objectif beauté?",
        "english": "What's your beauty goal?",
        "chinese": "您护肤的目标是？"
    },
    "goals_health": {
        "french": "Quelle est ton objectif santé?",
        "english": "What's your health goal?",
        "chinese": "您设立的健康目标是？"
    },
    "goals_lifeStyle": {
        "french": "Quelle est ton objectif mode de vie?",
        "english": "What's your lifestyle goal?",
        "chinese": "您对生活方式设立的目标是？"
    },
    "volume": {
        "french": "Volume",
        "english": "Volume",
        "chinese": "量"
    },
    "smoothing": {
        "french": "Lissage",
        "english": "Smoothing",
        "chinese": "柔顺"
    },
    "loops": {
        "french": "Boucles",
        "english": "Curls",
        "chinese": "卷"
    },
    "shine": {
        "french": "Brillance",
        "english": "Shine",
        "chinese": "光泽"
    },
    "hair_care": {
        "french": "Quels sont tes objectifs de soins pour tes cheveux?",
        "english": "What are your hair care goals?",
        "chinese": "你想通过头发护理达到什么状态？"
    },
    "growth": {
        "french": "Stimuler la croissance",
        "english": "Promote hair growth",
        "chinese": "帮助头发生长"
    },
    "strengthen": {
        "french": "Fortifier",
        "english": "Strengthen",
        "chinese": "加强"
    },
    "appease": {
        "french": "Apaiser",
        "english": "Smoothe",
        "chinese": "柔顺"
    },
    "hydrate": {
        "french": "Hydrater",
        "english": "Hydrate",
        "chinese": "保湿"
    },
    "feed": {
        "french": "Nourrir",
        "english": "Nourish",
        "chinese": "营养养护"
    },
    "repair": {
        "french": "Réparer",
        "english": "Repair",
        "chinese": "修护"
    },
    "sebum": {
        "french": "Régulation du sébum",
        "english": "Sebum regulation",
        "chinese": "皮质控制"
    },
    "product_type": {
        "french": "Quel type de produit souhaitez-vous utiliser?",
        "english": "What type of product would you like to use?",
        "chinese": "你青睐那种类型的产品？"
    },
    "shampoo": {
        "french": "Shampoing",
        "english": "Shampoo",
        "chinese": "洗发水"
    },
    "protect_coloration": {
        "french": "protéger la coloration",
        "english": "protect the color",
        "chinese": "防止脱色"
    },
    "protect_straighteningIron": {
        "french": "protéger du fer à lisser",
        "english": "protecting your straightening iron",
        "chinese": "保护头发"
    },
    "conditioner": {
        "french": "Après-shampoing",
        "english": "Conditioner",
        "chinese": "护发素"
    },
    "lotion": {
        "french": "Lotion",
        "english": "Lotion",
        "chinese": "乳液"
    },
    "spec_need": {
        "french": "Veuillez préciser si vous avez des besoins spécifiques?",
        "english": "Please specify if you have any specific needs?",
        "chinese": "您对产品是否有特别需求？"
    },
    "colored_hair": {
        "french": "Cheveux colorés",
        "english": "Colored hair",
        "chinese": "染色头发"
    },
    "brief_type": {
        "french": "Type de cheveux :",
        "english": "Hair type:",
        "chinese": "发质："
    },
    "brief_thick": {
        "french": "Épaisseur des cheveux :",
        "english": "Hair thickness:",
        "chinese": "头发疏密程度："
    },
    "brief_color": {
        "french": "Couleur des cheveux :",
        "english": "My hair color:",
        "chinese": "头发颜色："
    },
    "brief_scalp": {
        "french": "Mon cuir chevelu est",
        "english": "My scalp is",
        "chinese": "我的头皮是"
    },
    "brief_beauty": {
        "french": "Beauté des cheveux :",
        "english": "Hair beauty:",
        "chinese": "美容"
    },
    "brief_soin": {
        "french": "Soin des cheveux :",
        "english": "Hair care:",
        "chinese": "头发护理"
    },
    "brief_expectation": {
        "french": "Attente particulière:",
        "english": "Product expectation:",
        "chinese": "产品功效预期"
    },
    "recommend_products": {
        "french": "Je connais précisément la nature de tes cheveux, je suis en train d'élaborer ta recommandation de produits.",
        "english": "I know precisely your hair type, I am working on your product recommendation.",
        "chinese": "我已经了解您的发质，我正在为您准备产品推荐"
    },
    "see_selection": {
        "french": "Voir vos produits",
        "english": "See my selection",
        "chinese": "查看符合我的选择"
    },
    "see_selection_personalized": {
        "french": "Voir mes produits sur mesure",
        "english": "See my personalize selection",
        "chinese": "查看我的定制选择"
    },
    "length": {
        "french": "Quelle est la longueur de tes cheveux?",
        "english": "What is your hair length?",
        "chinese": "您的头发长短？"
    },
    "color": {
        "french": "Quelle est la couleur de tes cheveux?",
        "english": "What is your hair color?",
        "chinese": "您的发色是？"
    },
    "blond": {
        "french": "Blonds",
        "english": "Blond",
        "chinese": "金色"
    },
    "light_blond": {
        "french": "Châtains clairs",
        "english": "Light blond",
        "chinese": "浅金色"
    },
    "brown": {
        "french": "Brun",
        "english": "Brown",
        "chinese": "棕色"
    },
    "red": {
        "french": "Roux",
        "english": "Red",
        "chinese": "红色"
    },
    "white_hair": {
        "french": "Blancs",
        "english": "White",
        "chinese": "白色"
    },
    "colorful": {
        "french": "Colorés",
        "english": "Colorful",
        "chinese": "多色混合"
    },
    "highlighted": {
        "french": "Méchés",
        "english": "Highlighted",
        "chinese": "跳色"
    },
    "irritated": {
        "french": "Irrité",
        "english": "Irritated",
        "chinese": "易怒"
    },
    "none": {
        "french": "Rien de cela",
        "english": "None",
        "chinese": "没有"
    },
    "dandruff": {
        "french": "Es-tu sujet aux pellicules?",
        "english": "Are you prone to dandruff?",
        "chinese": "是否容易有头屑"
    },
    "dandruff_brief": {
        "french": "Pellicules:",
        "english": "Dandruff:",
        "chinese": "头皮屑"
    },
    "yes": {
        "french": "Oui",
        "english": "Yes",
        "chinese": "有机"
    },
    "no": {
        "french": "Non",
        "english": "No",
        "chinese": "没有"
    },
    "have_dandruff": {
        "french": "J'ai des pellicules",
        "english": "I have dandruff",
        "chinese": "我有头屑问题"
    },
    "hair_loss": {
        "french": "Es-tu sujet à la chute de cheveux?",
        "english": "Are you prone to hair loss?",
        "chinese": "是否有脱发倾向"
    },
    "hair_loss_brief": {
        "french": "EsType de cheveux : ",
        "english": "Hair type : ",
        "chinese": "头发类型 ： "
    },
    "have_hair_loss": {
        "french": "J'ai une chute de cheveux",
        "english": "I have hair loss",
        "chinese": "我有脱发问题"
    },
    "expectation": {
        "french": "As-tu une attente particulière sur tes produits?",
        "english": "Do you have any specific expectations for your products?",
        "chinese": "您对这个产品的使用效果有何特别期待？"
    },
    "bio": {
        "french": "Bio",
        "english": "Organic",
        "chinese": "有机"
    },
    "naturals": {
        "french": "Naturel",
        "english": "Natural",
        "chinese": "天然"
    },
    "made_FR": {
        "french": "Fabrication française",
        "english": "Made in France",
        "chinese": "法国制造"
    },
    "others": {
        "french": "Autre",
        "english": "Other",
        "chinese": "其他"
    },
    "lisse": {
        "french": "Les cheveux lisses sont caractérisés par une surface plane et uniforme. Ils ont tendance à avoir moins de frisottis et de boucles naturelles que les autres types de cheveux. Les cheveux lisses ont souvent un aspect brillant et réfléchissant la lumière, ce qui contribue à leur apparence saine et bien entretenue.",
        "english": "Straight hair is characterized by a flat and uniform surface. They tend to have fewer frizzes and natural curls than other hair types. Straight hair often has a shiny appearance that reflects light, contributing to a healthy and well-maintained look.",
        "chinese": "直发以其平坦均匀的表面而著称。相较于其他发质，直发往往更少出现毛躁和自然卷曲。直发通常呈现出有光泽的外观，反射光线，为健康和良好维护的形象添彩"
    },
    "ondule": {
        "french": "Les cheveux ondulés ont une texture intermédiaire entre les cheveux lisses et les cheveux bouclés. Ils présentent des ondulations naturelles et des boucles plus détendues. Les cheveux ondulés ont tendance à être plus secs, il est important de les hydrater régulièrement pour éviter les frisottis et les pointes fourchues. Utiliser des produits hydratants et des masques capillaires nourrissants peut aider à maintenir les cheveux ondulés en bonne santé.",
        "english": "Wavy hair has a texture that falls between straight and curly hair. It features natural waves and more relaxed curls. Wavy hair tends to be drier, so it's important to hydrate it regularly to prevent frizz and split ends. Using hydrating products and nourishing hair masks can help maintain the health of wavy hair.",
        "chinese": "波浪发质介于直发和卷发之间，具有自然的波浪和更加放松的卷曲。波浪发往往较为干燥，因此定期保湿对于防止毛躁和分叉末端非常重要。使用保湿产品和滋养发膜有助于维护波浪发的健康。"
    },
    "boucle": {
        "french": "Les cheveux bouclés ont tendance à être plus secs que les autres types de cheveux en raison de la structure de leurs boucles, ce qui rend plus difficile la distribution naturelle des huiles capillaires. Il est donc important de les hydrater régulièrement avec des produits adaptés pour maintenir leur santé et prévenir les frisottis.",
        "english": "Curly hair tends to be drier than other hair types due to the structure of its curls, which makes the natural distribution of scalp oils more challenging. Therefore, it's important to hydrate curly hair regularly with suitable products to maintain its health and prevent frizz.",
        "chinese": "卷发由于其卷曲的结构，往往比其他发质更为干燥，这使得头皮油脂的自然分布更加困难。因此，定期使用适合的产品保湿卷发非常重要，以维护其健康并防止毛躁。"
    },
    "crepus": {
        "french": "Les cheveux crépus ont une structure délicate et sont plus sujets à la casse que les autres types de cheveux. Ils nécessitent donc une manipulation douce et des soins appropriés pour éviter les dommages. Les cheveux crépus ont tendance à être plus secs en raison de leur structure en spirale, ce qui rend difficile la répartition naturelle des huiles capillaires le long des mèches. Il est essentiel de les hydrater régulièrement avec des produits riches en hydratation pour maintenir leur santé et leur élasticité.",
        "english": "Curly hair has a delicate structure and is more prone to breakage than other hair types. Therefore, it requires gentle handling and proper care to avoid damage. Curly hair tends to be drier due to its spiral structure, which makes the natural distribution of scalp oils along the strands challenging. Regular hydration with moisture-rich products is essential to maintain the health and elasticity of curly hair.",
        "chinese": "卷发拥有精致的结构，比其他发质更容易断裂。因此，需要温柔的处理和适当的护理以避免损伤。卷发由于其螺旋状的结构，往往更加干燥，这使得头皮油脂在发丝上的自然分布更具挑战性。定期使用富含水分的产品进行保湿对于维护卷发的健康和弹性至关重要。"
    },
    "longs": {
        "french": "Les cheveux longs ont tendance à être plus sujets à la sécheresse et aux pointes fourchues. Utilisez des produits capillaires hydratants tels que des masques ou des huiles pour les cheveux afin de maintenir une hydratation optimale. Évitez également d'utiliser des outils chauffants tels que les fers à lisser ou les fers à friser de manière excessive pour prévenir les dommages causés par la chaleur.",
        "english": "Long hair tends to be more susceptible to dryness and split ends. Use hydrating hair products such as masks or hair oils to maintain optimal hydration. Also, avoid excessive use of heat-styling tools like straighteners or curling irons to prevent heat-induced damage.",
        "chinese": "长发往往更容易干燥并出现分叉。使用保湿的头发产品，比如发膜或护发油，以维持最佳的水分平衡。此外，避免过多地使用热造型工具，如直发器或卷发棒，以防止因热损伤而导致的问题。"
    },
    "fin": {
        "french": "Les cheveux fins peuvent avoir tendance à devenir gras plus rapidement, il est donc préférable d'éviter de les laver tous les jours. Le lavage fréquent peut enlever les huiles naturelles nécessaires aux cheveux et les rendre encore plus plats. Essaye d'espacer les jours de lavage et utilise un shampooing sec pour rafraîchir les racines entre les lavages!",
        "english": "Fine hair may tend to become greasy more quickly, so it's best to avoid washing it every day. Frequent washing can strip the hair of its natural oils and make it even flatter. Try spacing out wash days and use dry shampoo to refresh the roots between washes!",
        "chinese": "细发可能更容易变得油腻，因此最好不要每天洗发。频繁洗发可能会剥夺头发的天然油脂，使其变得更加扁平。尝试延长洗发间隔，并在洗发之间使用干洗发喷雾来提神发根！"
    },
    "epais": {
        "french": "Les cheveux épais ont tendance à être plus secs, donc l'hydratation est essentielle. Utilisez des masques capillaires riches et hydratants une à deux fois par semaine pour nourrir vos cheveux en profondeur. Vous pouvez également appliquer un sérum capillaire ou une huile légère sur les pointes pour sceller l'hydratation.",
        "english": "Thick hair tends to be drier, so hydration is essential. Use rich, hydrating hair masks once or twice a week to deeply nourish your hair. You can also apply a hair serum or lightweight oil to the ends to seal in moisture.",
        "chinese": "浓密的头发往往更容易干燥，因此保湿至关重要。每周使用富含滋润成分的护发面膜一到两次，深度滋养你的头发。你还可以在发梢涂抹护发精华或轻型油来锁住水分。"
    },
    "meche": {
        "french": "Opte pour des shampooings, des revitalisants et des masques capillaires spécialement conçus pour les cheveux colorés ou méchés. Ces produits sont nourrissants et protecteurs pour aider à maintenir la couleur et à hydrater les cheveux.",
        "english": "Opt for shampoos, conditioners, and hair masks specially designed for colored or highlighted hair. These products are nourishing and protective to help maintain the color and hydrate the hair.",
        "chinese": "选择专门设计用于染色或突出显示的头发的洗发水、护发素和护发面膜。这些产品富含滋养成分并具有保护作用，有助于保持发色并保湿头发。"
    },
    "decolore": {
        "french": "Les cheveux décolorés sont généralement plus sensibles et plus fragiles en raison du processus chimique de décoloration. Ils peuvent devenir plus poreux, plus cassants et plus sujets à la sécheresse. Les cheveux décolorés ont donc besoin d'une hydratation intense. Utilisez des masques capillaires riches et hydratants au moins une fois par semaine pour restaurer l'hydratation perdue. Vous pouvez également utiliser des huiles capillaires nourrissantes pour sceller l'hydratation et renforcer la douceur des cheveux décolorés.",
        "english": "Bleached hair is generally more sensitive and fragile due to the chemical bleaching process. It can become more porous, brittle, and prone to dryness. Bleached hair requires intense hydration. Use rich and hydrating hair masks at least once a week to restore lost moisture. You can also use nourishing hair oils to seal in hydration and enhance the softness of bleached hair.",
        "chinese": "因化学漂白过程，漂白的头发通常更加敏感和脆弱。它可能变得更多孔、易折断，并容易干燥。漂白的头发需要强化的保湿。至少每周使用富含滋润成分的护发面膜，以恢复失去的水分。您还可以使用滋养护发油来锁住水分，增强漂白头发的柔软度。"
    },
    "chute_cheveux": {
        "french": "La chute de cheveux est un problème courant qui peut affecter hommes et femmes. Elle peut être causée par différents facteurs tels que le stress, les changements hormonaux, une alimentation déséquilibrée, des carences nutritives, des problèmes de santé ou encore des facteurs génétiques. Un cuir chevelu sain favorise une bonne santé capillaire. Assurez-vous de garder votre cuir chevelu propre et bien hydraté.",
        "english": "Hair loss is a common issue that can affect both men and women. It can be caused by various factors such as stress, hormonal changes, unbalanced diet, nutritional deficiencies, health issues, or even genetic factors. A healthy scalp promotes good hair health. Make sure to keep your scalp clean and well-hydrated.",
        "chinese": "脱发是一个常见的问题，可能会影响男性和女性。它可能由多种因素引起，如压力、荷尔蒙变化、不平衡的饮食、营养不足、健康问题，甚至是遗传因素。健康的头皮有助于保持良好的头发健康。确保保持头皮清洁和充分滋润。"
    },
    "hydratation": {
        "french": "Lorsque les cheveux ne reçoivent pas suffisamment d'hydratation, cela peut entraîner une perte de vitalité et de souplesse. N'hésitez pas à utiliser régulièrement des masques capillaires hydratants pour fournir une dose supplémentaire d'hydratation à vos cheveux!",
        "english": "When hair doesn't receive enough hydration, it can lead to a loss of vitality and flexibility. Don't hesitate to use hydrating hair masks regularly to provide an extra dose of hydration to your hair!",
        "chinese": "当头发没有足够的水分时，可能会导致失去活力和柔韧性。不要犹豫，定期使用保湿发膜，为你的头发提供额外的水分补给！"
    },
    "nutrition": {
        "french": "Lorsque les cheveux ne reçoivent pas suffisamment de nutriments essentiels, cela peut entraîner une détérioration de leur structure et de leur vitalité. Utilisez régulièrement des huiles capillaires nourrissantes pour hydrater et nourrir vos cheveux en profondeur!",
        "english": "When hair doesn't receive enough essential nutrients, it can lead to a deterioration of their structure and vitality. Use nourishing hair oils regularly to deeply hydrate and nourish your hair!",
        "chinese": "当头发没有获得足够的必要营养时，可能会导致结构和活力的恶化。定期使用滋养护发油，深度滋润和滋养你的头发！"
    },
    "volumes": {
        "french": "Avant de sécher vos cheveux, utilisez des mousses ou des sprays volumisants sur les racines et les longueurs. Ces produits aident à soulever les cheveux à la racine et à créer une apparence plus volumineuse!",
        "english": "Before blow-drying your hair, use volumizing mousse or sprays on the roots and lengths. These products help lift the hair at the roots and create a more voluminous appearance!",
        "chinese": "在吹干头发之前，可以在发根和发长上使用增容摩丝或喷雾。这些产品有助于抬升发根，创造出更丰盈的发型！"
    },
    "soleil_uv": {
        "french": "Choisissez des produits capillaires spécifiquement formulés pour offrir une protection contre les rayons UV. Orientez-vous vers des sprays, des huiles capillaires ou des leave-in conditioners qui contiennent un filtre solaire pour protéger vos cheveux des rayons UV.",
        "english": "Choose hair products specifically formulated to provide protection against UV rays. Look for sprays, hair oils, or leave-in conditioners that contain sun protection filters to shield your hair from UV rays.",
        "chinese": "选择专门配制以提供防紫外线辐射保护的护发产品。寻找含有防晒成分的喷雾、护发油或留香护发素，以保护头发免受紫外线的侵害。"
    },
    "frisottis": {
        "french": "L'humidité peut aggraver les frisottis, surtout par temps chaud et humide. Utilisez des produits capillaires anti-humidité ou appliquez une petite quantité de gel ou de crème coiffante pour créer une barrière protectrice contre l'humidité.",
        "english": "Humidity can worsen frizz, especially in warm and humid weather. Use anti-humidity hair products or apply a small amount of gel or styling cream to create a protective barrier against moisture.",
        "chinese": "湿度会加剧毛躁，尤其是在温暖而潮湿的天气中。使用抗潮湿护发产品或者涂抹少量的凝胶或造型霜，以创建一个防水屏障。"
    },
    "boucles": {
        "french": "Les cheveux bouclés sont naturellement plus sujets aux frisottis. Évitez de brosser vos cheveux quand ils sont secs, car cela peut casser les boucles et causer des frisottis. Utilisez plutôt un peigne à dents larges ou vos doigts pour démêler délicatement vos cheveux lorsque vous appliquez des produits coiffants ou lorsque vous les lavez.",
        "english": "Curly hair is naturally more prone to frizz. Avoid brushing your hair when it's dry, as it can break the curls and cause frizz. Instead, use a wide-tooth comb or your fingers to gently detangle your hair when applying styling products or washing it.",
        "chinese": "卷发天生更容易出现毛躁。避免在头发干燥时梳理，因为这可能会打断卷曲并导致毛躁。相反，在涂抹造型产品或洗头发时，使用宽齿梳子或手指轻柔地梳理头发。"
    },
    "brillances": {
        "french": "Lorsque vous rincez vos cheveux après le shampooing et le revitalisant, utilisez de l'eau froide ou tiède. L'eau froide aide à resserrer les cuticules des cheveux, ce qui les rend plus lisses et plus réfléchissants, améliorant ainsi leur brillance.",
        "english": "When rinsing your hair after shampooing and conditioning, use cold or lukewarm water. Cold water helps to close the hair cuticles, making them smoother and more reflective, thus enhancing their shine.",
        "chinese": "在洗发和护发后冲洗头发时，使用冷水或温水。冷水有助于关闭发丝的角质层，使其更加光滑和反光，从而增强头发的光泽。"
    },
    "protection_chaleurs": {
        "french": "Lorsque les cheveux sont exposés à des températures élevées, la chaleur peut pénétrer dans la cuticule du cheveu, ce qui peut entraîner une déshydratation, une perte de protéines et une fragilisation de la structure capillaire. L'utilisation d'une protection thermique crée une barrière entre les cheveux et la chaleur, réduisant ainsi les dommages potentiels.",
        "english": "When hair is exposed to high temperatures, heat can penetrate the hair cuticle, leading to dehydration, protein loss, and weakening of the hair structure. Using heat protection creates a barrier between the hair and the heat, reducing potential damage.",
        "chinese": "当头发暴露在高温下时，热量可以渗透到头发的角质层，导致脱水、蛋白质流失和头发结构的减弱。使用热量保护产品可以在头发和高温之间建立屏障，减少潜在的损害。"
    },
    "chlore": {
        "french": "Avant de nager, appliquez un produit capillaire protecteur spécialement formulé pour protéger les cheveux du chlore. Ces produits forment une barrière protectrice entre les cheveux et le chlore.",
        "english": "Before swimming, apply a protective hair product specially formulated to shield your hair from chlorine. These products create a protective barrier between the hair and chlorine.",
        "chinese": "在游泳前，涂抹一种专门配制的保护头发产品，以保护头发免受氯的影响。这些产品可以在头发和氯之间建立一层保护屏障。"
    },
    "pollution": {
        "french": "L'exposition prolongée à la pollution peut entraîner une perte de vitalité et d'éclat des cheveux. Les cheveux peuvent devenir ternes, manquer de brillance et avoir une apparence générale de mauvaise santé. Les cheveux bien hydratés et nourris sont mieux préparés pour faire face aux agressions de la pollution. Utilisez des masques capillaires hydratants et des huiles nourrissantes pour garder vos cheveux en bonne santé et les protéger des dommages.",
        "english": "Prolonged exposure to pollution can lead to a loss of vitality and luster in the hair. Hair can become dull, lack shine, and have an overall unhealthy appearance. Well-hydrated and nourished hair is better prepared to withstand the effects of pollution. Use hydrating hair masks and nourishing oils to keep your hair healthy and shield it from damage.",
        "chinese": "长时间暴露在污染物中可能导致头发失去活力和光泽。头发可能变得暗淡，缺乏光泽，整体呈现出不健康的外观。充分保湿和滋养的头发更能抵御污染的影响。使用保湿发膜和滋养的护发油，保持头发的健康并保护它免受损害。"
    },
    "sel_marrin": {
        "french": "Après une baignade dans la piscine ou dans l'eau de mer, rincez vos cheveux à l'eau claire pour éliminer les résidus de sel, qui peuvent causer des dommages aux cheveux.",
        "english": "After swimming in the pool or seawater, rinse your hair with clear water to remove salt residues, which can damage the hair.",
        "chinese": "在游泳池或海水中游泳后，用清水冲洗头发，以去除盐分残留物，这些盐分残留物可能会损害头发。"
    },
    "pellicule": {
        "french": "Lorsque vous utilisez le shampooing anti-pelliculaire, massez doucement votre cuir chevelu avec les bouts des doigts pendant quelques minutes. Cela aidera à exfolier les cellules mortes de la peau et à améliorer la circulation sanguine dans la région.",
        "english": "When using anti-dandruff shampoo, gently massage your scalp with your fingertips for a few minutes. This will help exfoliate dead skin cells and improve blood circulation in the area.",
        "chinese": "在使用抗头皮屑洗发水时，用指尖轻轻按摩头皮几分钟。这有助于去除死皮细胞并改善该区域的血液循环。"
    },
    "take_final_selfie": {
        "french": "Pour finir prenons 2 selfies de votre chevelure.",
        "english": "For ending take 2 selfies of your scalp.",
        "chinese": "最后，请拍摄2张头皮的照片"
    },
    "start_diag": {
        "french": "Commencons",
        "english": "Let's start",
        "chinese": "开始吧"
    },
}

export const LangageError = {
    "no_related": {
        "french": "Je n'ai pas bien compris, pourriez vous reformuler?",
        "english": "I didn't quite understand. Could you please rephrase?",
        "chinese": "抱歉，我可能没有理解您的问题。您可以请重新表达您的问题"
    },
    "moderation": {
        "french": "Merci d'employer un langage autorisé et adapté . Veuillez reformuler...",
        "english": "Please use authorized and appropriate language. Please rephrase...",
        "chinese": "请使用合适的语言措辞重新表达您的问题"
    },
    "chat_error": {
        "french": "Le chat s'est fini avec une erreur interne, veuillez réessayer en rafraîchissant la page.",
        "english": "The chat ended with an internal error, please try again by refreshing the page.",
        "chinese": "聊天因内部错误而结束，请刷新页面重试"
    }
}

export const AppName = {
    "chatbot_hair": {
        "french": "Salon de coiffure",
        "english": "Hair Salon",
        "chinese": "发廊"
    }
}

export const WordsHelper = {
    "and": {
        "french": "et",
        "english": "and",
        "chinese": "和"
    }
}
